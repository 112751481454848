import { svgIcon } from "@bitwarden/components";

export const SsoKeyIcon = svgIcon`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 100">
  <path class="tw-fill-art-primary" d="M28.573 23.888c6.488-8.225 16.56-13.51 27.87-13.51 15.454 0 28.595 9.864 33.446 23.62a23.969 23.969 0 0 1 2.844-.168c13.083 0 23.689 10.58 23.689 23.629 0 13.049-10.606 23.628-23.69 23.628H56.445v-2.393h36.289c11.757 0 21.289-9.507 21.289-21.236 0-11.728-9.532-21.235-21.29-21.235-1.182 0-2.34.096-3.469.28l-1.022.168-.315-.984c-4.26-13.293-16.746-22.915-31.482-22.915-10.712 0-20.234 5.083-26.274 12.968l-.31.404-.506.059C16.22 27.718 6.022 38.852 6.022 52.36c0 9.427 4.965 17.696 12.429 22.348l-.954 2.226C9.179 71.897 3.622 62.776 3.622 52.36c0-14.563 10.865-26.595 24.951-28.472Z"/>
  <path class="tw-fill-art-accent" fill-rule="evenodd" d="M110.626 64.594a.598.598 0 0 1-.393-.75 18.278 18.278 0 0 0-1.008-13.552 18.326 18.326 0 0 0-7.607-8.025.597.597 0 0 1-.233-.814.6.6 0 0 1 .816-.232 19.52 19.52 0 0 1 8.103 8.548 19.474 19.474 0 0 1 1.074 14.434.6.6 0 0 1-.752.39ZM33.17 30.116c-13.02 0-23.574 10.524-23.574 23.506a.6.6 0 0 1-1.2 0c0-13.642 11.092-24.702 24.773-24.702a.6.6 0 1 1 0 1.196Z" clip-rule="evenodd"/>
  <path class="tw-fill-art-primary" fill-rule="evenodd" d="M81.528 43.563a1.198 1.198 0 0 0-1.058-1.306l-11.08-1.273c-.32-.037-.641.055-.892.256L46.665 58.748c-6.998-3.579-15.75-3.07-22.226 2.123-8.84 7.088-10.054 19.941-2.89 28.787 7.168 8.847 20.1 10.352 28.888 3.306 6.524-5.232 8.897-13.726 6.742-21.358l3.146-2.523c.25-.2.41-.493.441-.812l.509-5.09 5.29.609c.32.036.64-.056.89-.257.251-.2.41-.493.442-.812l.144-1.439 1.169.135c.317.036.635-.055.885-.252s.41-.485.446-.8l.612-5.372 5.764.663c.32.036.641-.056.892-.257l2.366-1.897c.25-.201.41-.494.441-.812l.912-9.127ZM68.289 58.904l-1.186-.136c-.32-.037-.64.056-.891.257-.25.2-.41.493-.441.812l-.144 1.438-5.29-.607c-.32-.037-.64.055-.891.256-.25.201-.41.494-.441.812l-.58 5.8-3.384 2.713a1.191 1.191 0 0 0-.389 1.3c2.266 6.94.211 14.794-5.724 19.553-7.726 6.196-19.152 4.902-25.508-2.944-6.357-7.848-5.248-19.19 2.527-25.425 5.886-4.72 13.952-5.061 20.26-1.516.43.241.962.198 1.345-.11l22.063-17.691 9.405 1.08-.745 7.458-1.583 1.27-6.46-.743a1.205 1.205 0 0 0-.885.251c-.25.198-.41.486-.446.801l-.612 5.371Z" clip-rule="evenodd"/>
  <path class="tw-fill-art-accent" fill-rule="evenodd" d="M35.251 78.67c2.144 2.647 1.721 6.453-.864 8.526-2.587 2.074-6.414 1.676-8.558-.97-2.137-2.638-1.78-6.405.865-8.525 2.653-2.127 6.468-1.61 8.557.97Zm-2.373 6.665c1.543-1.237 1.823-3.535.503-5.164-1.291-1.595-3.602-1.873-5.179-.609-1.584 1.271-1.829 3.528-.503 5.165 1.32 1.629 3.637 1.845 5.18.608Z" clip-rule="evenodd"/>
</svg>
`;
