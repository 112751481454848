<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="small">
    <span bitDialogTitle>
      {{ "addToFolder" | i18n }}
    </span>
    <span bitDialogContent>
      <p>{{ "moveSelectedItemsDesc" | i18n: cipherIds.length }}</p>
      <bit-form-field>
        <bit-label for="folder">{{ "selectFolder" | i18n }}</bit-label>
        <bit-select formControlName="folderId">
          <bit-option *ngFor="let f of folders$ | async" [value]="f.id" [label]="f.name">
          </bit-option>
        </bit-select>
      </bit-form-field>
    </span>
    <ng-container bitDialogFooter>
      <button bitButton bitFormButton type="submit" buttonType="primary">
        {{ "save" | i18n }}
      </button>
      <button bitButton bitFormButton type="button" buttonType="secondary" (click)="cancel()">
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
