<bit-callout type="warning">
  {{ "sendOptionsExemption" | i18n }}
</bit-callout>

<bit-form-control>
  <input type="checkbox" bitCheckbox [formControl]="enabled" id="enabled" />
  <bit-label>{{ "turnOn" | i18n }}</bit-label>
</bit-form-control>

<div [formGroup]="data">
  <h3 bitTypography="h3" class="tw-mt-4">{{ "options" | i18n }}</h3>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="disableHideEmail" id="disableHideEmail" />
    <bit-label>{{ "disableHideEmail" | i18n }}</bit-label>
  </bit-form-control>
</div>
