<form [formGroup]="identityForm">
  <bit-section>
    <bit-section-header>
      <h2 bitTypography="h6">{{ "personalDetails" | i18n }}</h2>
    </bit-section-header>
    <bit-card>
      <bit-form-field>
        <bit-label>
          {{ "title" | i18n }}
        </bit-label>
        <bit-select formControlName="title">
          <bit-option
            *ngFor="let title of identityTitleOptions"
            [value]="title.value"
            [label]="title.name"
          >
          </bit-option>
        </bit-select>
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "firstName" | i18n }}
        </bit-label>
        <input bitInput formControlName="firstName" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "middleName" | i18n }}
        </bit-label>
        <input bitInput formControlName="middleName" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "lastName" | i18n }}
        </bit-label>
        <input bitInput formControlName="lastName" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "username" | i18n }}
        </bit-label>
        <input bitInput formControlName="username" />
      </bit-form-field>
      <bit-form-field disableMargin>
        <bit-label>
          {{ "company" | i18n }}
        </bit-label>
        <input bitInput formControlName="company" />
      </bit-form-field>
    </bit-card>
  </bit-section>
  <bit-section>
    <bit-section-header>
      <h2 bitTypography="h6">{{ "identification" | i18n }}</h2>
    </bit-section-header>
    <bit-card>
      <bit-form-field>
        <bit-label>
          {{ "ssn" | i18n }}
        </bit-label>
        <input formControlName="ssn" bitInput type="password" />
        <button
          type="button"
          bitIconButton
          bitSuffix
          bitPasswordInputToggle
          data-testid="visibility-for-ssn"
        ></button>
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "passportNumber" | i18n }}
        </bit-label>
        <input formControlName="passportNumber" bitInput type="password" />
        <button
          type="button"
          bitIconButton
          bitSuffix
          bitPasswordInputToggle
          data-testid="visibility-for-passport-number"
        ></button>
      </bit-form-field>
      <bit-form-field disableMargin>
        <bit-label>
          {{ "licenseNumber" | i18n }}
        </bit-label>
        <input bitInput formControlName="licenseNumber" />
      </bit-form-field>
    </bit-card>
  </bit-section>
  <bit-section>
    <bit-section-header>
      <h2 bitTypography="h6">{{ "contactInfo" | i18n }}</h2>
    </bit-section-header>
    <bit-card>
      <bit-form-field>
        <bit-label>
          {{ "email" | i18n }}
        </bit-label>
        <input bitInput formControlName="email" />
      </bit-form-field>
      <bit-form-field disableMargin>
        <bit-label>
          {{ "phone" | i18n }}
        </bit-label>
        <input bitInput formControlName="phone" />
      </bit-form-field>
    </bit-card>
  </bit-section>
  <bit-section>
    <bit-section-header>
      <h2 bitTypography="h6">{{ "address" | i18n }}</h2>
    </bit-section-header>
    <bit-card>
      <bit-form-field>
        <bit-label>
          {{ "address1" | i18n }}
        </bit-label>
        <input bitInput formControlName="address1" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "address2" | i18n }}
        </bit-label>
        <input bitInput formControlName="address2" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "address3" | i18n }}
        </bit-label>
        <input bitInput formControlName="address3" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "cityTown" | i18n }}
        </bit-label>
        <input bitInput formControlName="city" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "stateProvince" | i18n }}
        </bit-label>
        <input bitInput formControlName="state" />
      </bit-form-field>
      <bit-form-field>
        <bit-label>
          {{ "zipPostalCode" | i18n }}
        </bit-label>
        <input bitInput formControlName="postalCode" />
      </bit-form-field>
      <bit-form-field disableMargin>
        <bit-label>
          {{ "country" | i18n }}
        </bit-label>
        <input bitInput formControlName="country" />
      </bit-form-field>
    </bit-card>
  </bit-section>
</form>
