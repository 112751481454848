<bit-item-action class="item-main-content tw-flex tw-flex-1 tw-overflow-hidden">
  <ng-content></ng-content>
</bit-item-action>

<div
  #endSlot
  class="tw-p-2 tw-flex tw-gap-1 tw-items-center"
  [hidden]="endSlot.childElementCount === 0"
>
  <ng-content select="[slot=end]"></ng-content>
</div>
