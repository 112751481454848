<bit-section *ngIf="sends?.length > 0">
  <bit-section-header>
    <h2 class="tw-font-bold" bitTypography="h6">
      {{ headerText }}
    </h2>
    <span bitTypography="body1" slot="end">{{ sends.length }}</span>
  </bit-section-header>
  <bit-item-group>
    <bit-item *ngFor="let send of sends">
      <button
        bit-item-content
        appA11yTitle="{{ 'edit' | i18n }} - {{ send.name }}"
        routerLink="/edit-send"
        [queryParams]="{ sendId: send.id, type: send.type }"
        appStopClick
        type="button"
      >
        <i
          slot="start"
          *ngIf="send.type === sendType.Text"
          class="bwi bwi-file-text tw-text-2xl text-muted"
        ></i>
        <i
          slot="start"
          *ngIf="send.type === sendType.File"
          class="bwi bwi-file tw-text-2xl text-muted"
        ></i>
        {{ send.name }}
        <span slot="secondary">
          {{ "deletionDate" | i18n }}:&nbsp;{{ send.deletionDate | date: "mediumDate" }}
        </span>
      </button>
      <ng-container slot="end">
        <bit-item-action>
          <button
            class="tw-p-1"
            bitIconButton="bwi-clone"
            size="small"
            type="button"
            (click)="copySendLink(send)"
            appA11yTitle="{{ 'copyLink' | i18n }} - {{ send.name }}"
          ></button>
        </bit-item-action>
        <bit-item-action>
          <button
            bitIconButton="bwi-trash"
            size="small"
            type="button"
            (click)="deleteSend(send)"
            appA11yTitle="{{ 'delete' | i18n }} - {{ send.name }}"
          ></button>
        </bit-item-action>
      </ng-container>
    </bit-item>
  </bit-item-group>
</bit-section>
