<div class="tw-flex tw-items-center tw-justify-center" *ngIf="loading">
  <i class="bwi bwi-spinner bwi-spin bwi-3x" aria-hidden="true"></i>
</div>

<auth-input-password
  *ngIf="!loading"
  [email]="email"
  [masterPasswordPolicyOptions]="masterPasswordPolicyOptions"
  [loading]="submitting"
  (onPasswordFormSubmit)="handlePasswordFormSubmit($event)"
  [buttonText]="'createAccount' | i18n"
></auth-input-password>
