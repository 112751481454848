<div *ngIf="loading" class="tw-text-center">
  <i
    class="bwi bwi-spinner bwi-spin bwi-2x tw-text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="tw-sr-only">{{ "loading" | i18n }}</span>
</div>

<div *ngIf="!loading">
  <p>{{ "convertOrganizationEncryptionDesc" | i18n: organization.name }}</p>

  <button
    bitButton
    type="button"
    buttonType="primary"
    class="tw-w-full tw-mb-2"
    [bitAction]="convert"
    [block]="true"
  >
    {{ "removeMasterPassword" | i18n }}
  </button>
  <button
    bitButton
    type="button"
    buttonType="secondary"
    class="tw-w-full"
    [bitAction]="leave"
    [block]="true"
  >
    {{ "leaveOrganization" | i18n }}
  </button>
</div>
