<figure class="tw-text-center">
  <p class="tw-mx-auto tw-my-2 tw-max-w-xl tw-px-4 tw-text-center">
    Recommended by industry experts
  </p>
  <div class="tw-flex tw-flex-wrap tw-gap-8 tw-items-center tw-justify-center tw-mb-4">
    <div class="tw-flex tw-gap-8">
      <img src="../../images/register-layout/cnet-logo.svg" class="tw-w-32" alt="CNET Logo" />
      <img
        src="../../images/register-layout/wired-logo.png"
        class="tw-w-32 tw-object-contain"
        alt="WIRED Logo"
      />
    </div>
    <div class="tw-flex tw-gap-8">
      <img
        src="../../images/register-layout/new-york-times-logo.svg"
        class="tw-w-32"
        alt="New York Times Logo"
      />
      <img src="../../images/register-layout/pcmag-logo.svg" class="tw-w-32" alt="PC Mag Logo" />
    </div>
  </div>
  <blockquote>
    &ldquo;Bitwarden is currently CNET's top pick for the best password manager, thanks in part to
    its commitment to transparency and its unbeatable free tier.&rdquo;
  </blockquote>
  <p class="tw-font-bold">Best Password Manager in 2024</p>
</figure>
