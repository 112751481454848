<bit-callout type="info" title="{{ 'prerequisite' | i18n }}">
  {{ "requireSsoPolicyReq" | i18n }}
</bit-callout>
<bit-callout type="warning">
  {{ "requireSsoExemption" | i18n }}
</bit-callout>

<bit-form-control>
  <input type="checkbox" bitCheckbox [formControl]="enabled" id="enabled" />
  <bit-label>{{ "turnOn" | i18n }}</bit-label>
</bit-form-control>
