<form *ngIf="authed" [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="large">
    <span bitDialogTitle>
      {{ "twoStepLogin" | i18n }}
      <span bitTypography="body1">YubiKey</span>
    </span>
    <ng-container bitDialogContent>
      <app-callout
        *ngIf="enabled"
        type="success"
        title="{{ 'enabled' | i18n }}"
        icon="bwi bwi-check-circle"
      >
        {{ "twoStepLoginProviderEnabled" | i18n }}
      </app-callout>
      <app-callout type="warning">
        <p bitTypography="body1">{{ "twoFactorYubikeyWarning" | i18n }}</p>
        <ul class="tw-mb-0" bitTypography="body1">
          <li>{{ "twoFactorYubikeySupportUsb" | i18n }}</li>
          <li>{{ "twoFactorYubikeySupportMobile" | i18n }}</li>
        </ul>
      </app-callout>
      <img class="tw-float-right mfaType3" alt="YubiKey OTP security key logo" />
      <p bitTypography="body1">{{ "twoFactorYubikeyAdd" | i18n }}:</p>
      <ol bitTypography="body1">
        <li>{{ "twoFactorYubikeyPlugIn" | i18n }}</li>
        <li>{{ "twoFactorYubikeySelectKey" | i18n }}</li>
        <li>{{ "twoFactorYubikeyTouchButton" | i18n }}</li>
        <li>{{ "twoFactorYubikeySaveForm" | i18n }}</li>
      </ol>
      <hr />
      <div class="tw-grid tw-grid-cols-12 tw-gap-4" formArrayName="formKeys">
        <div class="tw-col-span-6" *ngFor="let k of keys; let i = index">
          <div [formGroupName]="i">
            <bit-label>{{ "yubikeyX" | i18n: i + 1 }}</bit-label>
            <bit-form-field *ngIf="!keys[i].existingKey">
              <input bitInput type="password" formControlName="key" appInputVerbatim />
            </bit-form-field>
            <div class="tw-flex tw-justify-between tw-mb-6" *ngIf="keys[i].existingKey">
              <span class="tw-mr-2 tw-self-center">{{ keys[i].existingKey }}</span>
              <button
                bitIconButton="bwi-minus-circle"
                type="button"
                buttonType="danger"
                (click)="remove(i)"
                appA11yTitle="{{ 'remove' | i18n }}"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <p bitTypography="body1" class="tw-font-bold tw-mb-2">{{ "nfcSupport" | i18n }}</p>
      <bit-form-control [disableMargin]="true">
        <bit-label>{{ "twoFactorYubikeySupportsNfc" | i18n }}</bit-label>
        <input bitCheckbox type="checkbox" formControlName="anyKeyHasNfc" />
        <bit-hint class="tw-text-sm">{{ "twoFactorYubikeySupportsNfcDesc" | i18n }}</bit-hint>
      </bit-form-control>
    </ng-container>
    <ng-container bitDialogFooter>
      <button bitButton bitFormButton type="submit" buttonType="primary">
        {{ "save" | i18n }}
      </button>
      <button
        *ngIf="enabled"
        bitButton
        bitFormButton
        type="button"
        buttonType="secondary"
        [bitAction]="disable"
      >
        {{ "disableAllKeys" | i18n }}
      </button>
      <button
        bitButton
        bitFormButton
        type="button"
        buttonType="secondary"
        [bitDialogClose]="this.enabled"
      >
        {{ "close" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
