<p bitTypography="body1">{{ "uploadLicenseFileOrg" | i18n }}</p>
<form [formGroup]="form" [bitSubmit]="submit">
  <bit-form-field>
    <bit-label>{{ description | i18n }}</bit-label>
    <div>
      <button bitButton type="button" buttonType="secondary" (click)="fileSelector.click()">
        {{ "chooseFile" | i18n }}
      </button>
      {{ form.value.file ? form.value.file.name : ("noFileChosen" | i18n) }}
    </div>
    <input
      #fileSelector
      bitInput
      type="file"
      formControlName="file"
      (change)="onLicenseFileSelectedChanged($event)"
      accept="application/JSON"
      hidden
      class="tw-hidden"
    />
    <bit-hint>{{ "licenseFileDesc" | i18n: hintFileName }}</bit-hint>
  </bit-form-field>
  <button type="submit" bitButton bitFormButton buttonType="primary">
    {{ "submit" | i18n }}
  </button>
</form>
