<form [formGroup]="takeoverForm" [bitSubmit]="submit">
  <bit-dialog dialogSize="large">
    <span bitDialogTitle>
      {{ "takeover" | i18n }}
      <small class="tw-text-muted" *ngIf="params.name">{{ params.name }}</small>
    </span>
    <div bitDialogContent>
      <bit-callout type="warning">{{ "loggedOutWarning" | i18n }}</bit-callout>
      <auth-password-callout [policy]="enforcedPolicyOptions" *ngIf="enforcedPolicyOptions">
      </auth-password-callout>
      <div class="tw-w-full tw-flex tw-gap-4">
        <div class="tw-relative tw-flex-1">
          <bit-form-field disableMargin class="tw-mb-2">
            <bit-label>{{ "newMasterPass" | i18n }}</bit-label>
            <input
              bitInput
              type="password"
              autocomplete="new-password"
              formControlName="masterPassword"
            />
            <button type="button" bitSuffix bitIconButton bitPasswordInputToggle></button>
          </bit-form-field>
          <app-password-strength
            [password]="takeoverForm.value.masterPassword"
            [email]="email"
            [showText]="true"
            (passwordStrengthResult)="getStrengthResult($event)"
          >
          </app-password-strength>
        </div>
        <div class="tw-relative tw-flex-1">
          <bit-form-field disableMargin class="tw-mb-2">
            <bit-label>{{ "confirmNewMasterPass" | i18n }}</bit-label>
            <input
              bitInput
              type="password"
              autocomplete="new-password"
              formControlName="masterPasswordRetype"
            />
            <button type="button" bitSuffix bitIconButton bitPasswordInputToggle></button>
          </bit-form-field>
        </div>
      </div>
    </div>
    <div bitDialogFooter>
      <button type="submit" bitButton bitFormButton buttonType="primary">
        {{ "save" | i18n }}
      </button>
      <button bitButton bitFormButton type="button" buttonType="secondary" bitDialogClose>
        {{ "cancel" | i18n }}
      </button>
    </div>
  </bit-dialog>
</form>
