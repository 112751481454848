<app-vertical-stepper #stepper linear>
  <app-vertical-step
    label="{{ 'createAccount' | i18n | titlecase }}"
    [editable]="false"
    [subLabel]="createAccountLabel"
    [addSubLabelSpacing]="true"
  >
    <app-register-form
      [referenceDataValue]="referenceEventRequest"
      [isInTrialFlow]="true"
      (createdAccount)="accountCreated($event)"
    >
    </app-register-form>
  </app-vertical-step>
  <app-vertical-step
    label="{{ 'organizationInformation' | i18n | titlecase }}"
    [subLabel]="subLabels.organizationInfo"
  >
    <app-org-info [nameOnly]="true" [formGroup]="formGroup"></app-org-info>
    <button
      type="button"
      bitButton
      buttonType="primary"
      [disabled]="formGroup.get('name').invalid"
      [loading]="createOrganizationLoading"
      (click)="createOrganizationOnTrial()"
      *ngIf="enableTrialPayment$ | async"
    >
      {{ "startTrial" | i18n }}
    </button>
    <button
      type="button"
      bitButton
      buttonType="primary"
      [disabled]="formGroup.get('name').invalid"
      [loading]="createOrganizationLoading"
      cdkStepperNext
      *ngIf="!(enableTrialPayment$ | async)"
    >
      {{ "next" | i18n }}
    </button>
  </app-vertical-step>
  <app-vertical-step
    label="{{ 'billing' | i18n | titlecase }}"
    [subLabel]="billingSubLabel"
    *ngIf="!(enableTrialPayment$ | async)"
  >
    <app-trial-billing-step
      *ngIf="stepper.selectedIndex === 2"
      [organizationInfo]="{
        name: formGroup.get('name').value,
        email: formGroup.get('email').value,
        type: productType,
      }"
      [subscriptionProduct]="SubscriptionProduct.SecretsManager"
      (steppedBack)="steppedBack()"
      (organizationCreated)="organizationCreated($event)"
    ></app-trial-billing-step>
  </app-vertical-step>
  <app-vertical-step label="{{ 'confirmationDetails' | i18n | titlecase }}">
    <app-trial-confirmation-details
      [email]="formGroup.get('email').value"
      [orgLabel]="organizationTypeQueryParameter"
    ></app-trial-confirmation-details>
    <div class="tw-mb-3 tw-flex">
      <button type="button" bitButton buttonType="primary" (click)="navigateToSecretsManager()">
        {{ "getStarted" | i18n | titlecase }}
      </button>
      <button
        type="button"
        bitButton
        buttonType="secondary"
        (click)="navigateToMembers()"
        class="tw-ml-3 tw-inline-flex tw-items-center tw-px-3"
      >
        {{ "inviteUsers" | i18n }}
      </button>
    </div>
  </app-vertical-step>
</app-vertical-stepper>
