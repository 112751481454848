<bit-toggle-group
  fullWidth
  class="tw-mb-4"
  [selected]="credentialType$ | async"
  (selectedChange)="onCredentialTypeChanged($event)"
  attr.aria-label="{{ 'type' | i18n }}"
>
  <bit-toggle *ngFor="let option of passwordOptions$ | async" [value]="option.value">
    {{ option.label }}
  </bit-toggle>
</bit-toggle-group>
<bit-card class="tw-flex tw-justify-between tw-mb-4">
  <div class="tw-grow tw-flex tw-items-center">
    <bit-color-password class="tw-font-mono" [password]="value$ | async"></bit-color-password>
  </div>
  <div class="tw-flex tw-items-center tw-space-x-1">
    <button
      type="button"
      bitIconButton="bwi-generate"
      buttonType="main"
      (click)="generate('user request')"
      [appA11yTitle]="credentialTypeGenerateLabel$ | async"
      [disabled]="!(algorithm$ | async)"
    >
      {{ credentialTypeGenerateLabel$ | async }}
    </button>
    <button
      type="button"
      bitIconButton="bwi-clone"
      buttonType="main"
      showToast
      [appA11yTitle]="credentialTypeCopyLabel$ | async"
      [appCopyClick]="value$ | async"
      [valueLabel]="credentialTypeLabel$ | async"
      [disabled]="!(algorithm$ | async)"
    ></button>
  </div>
</bit-card>
<tools-password-settings
  class="tw-mt-6"
  *ngIf="(algorithm$ | async)?.id === 'password'"
  [userId]="this.userId$ | async"
  [disableMargin]="disableMargin"
  (onUpdated)="generate('password settings')"
/>
<tools-passphrase-settings
  class="tw-mt-6"
  *ngIf="(algorithm$ | async)?.id === 'passphrase'"
  [userId]="this.userId$ | async"
  (onUpdated)="generate('passphrase settings')"
  [disableMargin]="disableMargin"
/>
