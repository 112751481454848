<form [formGroup]="formGroup" *ngIf="!hideEnvSelector">
  <bit-form-field>
    <bit-label>{{ "creatingAccountOn" | i18n }}</bit-label>
    <bit-select formControlName="selectedRegion" (closed)="onSelectClosed()">
      <bit-option
        *ngFor="let regionConfig of availableRegionConfigs"
        [value]="regionConfig"
        [label]="regionConfig.domain"
      ></bit-option>
      <bit-option
        *ngIf="isDesktopOrBrowserExtension"
        [value]="ServerEnvironmentType.SelfHosted"
        [label]="'selfHostedServer' | i18n"
      ></bit-option>
    </bit-select>
  </bit-form-field>
</form>
