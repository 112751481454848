<div class="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-6">
  <div class="tw-max-w-sm tw-flex tw-flex-col tw-items-center">
    <bit-icon [icon]="icon" aria-hidden="true"></bit-icon>
    <h3 class="tw-font-semibold tw-text-center">
      <ng-content select="[slot=title]"></ng-content>
    </h3>
    <p class="tw-text-center">
      <ng-content select="[slot=description]"></ng-content>
    </p>
  </div>
  <div class="tw-space-x-2">
    <ng-content select="[slot=button]"></ng-content>
  </div>
</div>
