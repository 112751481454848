<form [formGroup]="formGroup" [bitSubmit]="submit">
  <auth-password-callout
    *ngIf="masterPasswordPolicyOptions"
    [policy]="masterPasswordPolicyOptions"
  ></auth-password-callout>

  <div class="tw-mb-6">
    <bit-form-field>
      <bit-label>{{ "masterPassword" | i18n }}</bit-label>
      <input
        id="input-password-form_password"
        bitInput
        type="password"
        formControlName="password"
      />
      <button
        type="button"
        bitIconButton
        bitSuffix
        bitPasswordInputToggle
        [(toggled)]="showPassword"
      ></button>
      <bit-hint>
        <span class="tw-font-bold">{{ "important" | i18n }} </span>
        {{ "masterPassImportant" | i18n }}
        {{ minPasswordLengthMsg }}.
      </bit-hint>
    </bit-form-field>

    <tools-password-strength
      [showText]="true"
      [email]="email"
      [password]="formGroup.controls.password.value"
      (passwordStrengthScore)="getPasswordStrengthScore($event)"
    ></tools-password-strength>
  </div>

  <bit-form-field>
    <bit-label>{{ "confirmMasterPassword" | i18n }}</bit-label>
    <input
      id="input-password-form_confirmed-password"
      bitInput
      type="password"
      formControlName="confirmedPassword"
    />
    <button
      type="button"
      bitIconButton
      bitSuffix
      bitPasswordInputToggle
      [(toggled)]="showPassword"
    ></button>
  </bit-form-field>

  <bit-form-field>
    <bit-label>{{ "masterPassHintLabel" | i18n }}</bit-label>
    <input bitInput formControlName="hint" />
    <bit-hint>
      {{ "masterPassHintText" | i18n: formGroup.value.hint.length : maxHintLength.toString() }}
    </bit-hint>
  </bit-form-field>

  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="checkForBreaches" />
    <bit-label>{{ "checkForBreaches" | i18n }}</bit-label>
  </bit-form-control>

  <button
    type="submit"
    bitButton
    bitFormButton
    buttonType="primary"
    [block]="btnBlock"
    [loading]="loading"
  >
    {{ buttonText || ("setMasterPassword" | i18n) }}
  </button>

  <bit-error-summary *ngIf="showErrorSummary" [formGroup]="formGroup"></bit-error-summary>
</form>
