<h1 bitTypography="h1" class="tw-mt-16 tw-pb-2.5 !tw-text-danger">{{ "dangerZone" | i18n }}</h1>

<div class="tw-rounded tw-border tw-border-solid tw-border-danger-600 tw-p-5">
  <p>
    {{
      (accountDeprovisioningEnabled$ | async) && content.children.length === 1
        ? ("dangerZoneDescSingular" | i18n)
        : ("dangerZoneDesc" | i18n)
    }}
  </p>

  <div #content class="tw-flex tw-flex-row tw-gap-2">
    <ng-content></ng-content>
  </div>
</div>
