<bit-card class="tw-flex tw-justify-between tw-mb-4">
  <div class="tw-grow tw-flex tw-items-center">
    <bit-color-password class="tw-font-mono" [password]="value$ | async"></bit-color-password>
  </div>
  <div class="tw-flex tw-items-center tw-space-x-1">
    <button
      type="button"
      bitIconButton="bwi-generate"
      buttonType="main"
      (click)="generate('user request')"
      [appA11yTitle]="credentialTypeGenerateLabel$ | async"
      [disabled]="!(algorithm$ | async)"
    >
      {{ credentialTypeGenerateLabel$ | async }}
    </button>
    <button
      type="button"
      bitIconButton="bwi-clone"
      buttonType="main"
      showToast
      [appA11yTitle]="credentialTypeCopyLabel$ | async"
      [appCopyClick]="value$ | async"
      [valueLabel]="credentialTypeLabel$ | async"
      [disabled]="!(algorithm$ | async)"
    >
      {{ credentialTypeCopyLabel$ | async }}
    </button>
  </div>
</bit-card>
<bit-section [disableMargin]="disableMargin">
  <bit-section-header>
    <h2 bitTypography="h6">{{ "options" | i18n }}</h2>
  </bit-section-header>
  <div [ngClass]="{ 'tw-mb-4': !disableMargin }">
    <bit-card>
      <form class="box" [formGroup]="username" class="tw-container">
        <bit-form-field>
          <bit-label>{{ "type" | i18n }}</bit-label>
          <bit-select
            [items]="typeOptions$ | async"
            formControlName="nav"
            data-testid="username-type"
          >
          </bit-select>
          <bit-hint *ngIf="!!(credentialTypeHint$ | async)">{{
            credentialTypeHint$ | async
          }}</bit-hint>
        </bit-form-field>
      </form>
      <form *ngIf="showForwarder$ | async" [formGroup]="forwarder" class="box tw-container">
        <bit-form-field>
          <bit-label>{{ "service" | i18n }}</bit-label>
          <bit-select
            [items]="forwarderOptions$ | async"
            formControlName="nav"
            data-testid="email-forwarding-service"
          >
          </bit-select>
        </bit-form-field>
      </form>
      <tools-catchall-settings
        *ngIf="(algorithm$ | async)?.id === 'catchall'"
        [userId]="this.userId$ | async"
        (onUpdated)="generate('catchall settings')"
      />
      <tools-forwarder-settings
        *ngIf="!!(forwarderId$ | async)"
        [forwarder]="forwarderId$ | async"
        [userId]="this.userId$ | async"
      />
      <tools-subaddress-settings
        *ngIf="(algorithm$ | async)?.id === 'subaddress'"
        [userId]="this.userId$ | async"
        (onUpdated)="generate('subaddress settings')"
      />
      <tools-username-settings
        *ngIf="(algorithm$ | async)?.id === 'username'"
        [userId]="this.userId$ | async"
        (onUpdated)="generate('username settings')"
      />
    </bit-card>
  </div>
</bit-section>
