<bit-banner
  id="free-trial-banner"
  class="-tw-m-6 tw-flex tw-flex-col tw-pb-6"
  bannerType="premium"
  icon="bwi-billing"
  [showClose]="false"
  *ngIf="freeTrialData?.shownBanner"
>
  {{ freeTrialData?.message }}
  <a
    bitLink
    linkType="secondary"
    (click)="changePayment()"
    class="tw-cursor-pointer"
    rel="noreferrer noopener"
  >
    {{ "clickHereToAddPaymentMethod" | i18n }}
  </a>
</bit-banner>

<app-header *ngIf="organizationId">
  <button
    type="button"
    bitButton
    buttonType="secondary"
    [bitAction]="load"
    class="tw-ml-auto"
    *ngIf="firstLoaded"
    [disabled]="loading"
  >
    <i class="bwi bwi-refresh bwi-fw" [ngClass]="{ 'bwi-spin': loading }" aria-hidden="true"></i>
    {{ "refresh" | i18n }}
  </button>
</app-header>

<bit-container>
  <!-- TODO: Organization and individual should use different "page" components -->
  <h2 bitTypography="h1" *ngIf="!organizationId">{{ "paymentMethod" | i18n }}</h2>

  <ng-container *ngIf="!firstLoaded && loading">
    <i
      class="bwi bwi-spinner bwi-spin tw-text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="tw-sr-only">{{ "loading" | i18n }}</span>
  </ng-container>
  <ng-container *ngIf="billing">
    <bit-section>
      <h2 bitTypography="h2">
        {{ (isCreditBalance ? "accountCredit" : "accountBalance") | i18n }}
      </h2>
      <p class="tw-text-lg tw-font-bold">{{ creditOrBalance | currency: "$" }}</p>
      <p bitTypography="body1">{{ "creditAppliedDesc" | i18n }}</p>
      <button type="button" bitButton buttonType="secondary" [bitAction]="addCredit">
        {{ "addCredit" | i18n }}
      </button>
    </bit-section>
    <bit-section>
      <h2 bitTypography="h2">{{ "paymentMethod" | i18n }}</h2>
      <p *ngIf="!paymentSource" bitTypography="body1">{{ "noPaymentMethod" | i18n }}</p>
      <ng-container *ngIf="paymentSource">
        <bit-callout
          type="warning"
          title="{{ 'verifyBankAccount' | i18n }}"
          *ngIf="
            forOrganization &&
            paymentSource.type === paymentMethodType.BankAccount &&
            paymentSource.needsVerification
          "
        >
          <p bitTypography="body1">
            {{ "verifyBankAccountDesc" | i18n }} {{ "verifyBankAccountFailureWarning" | i18n }}
          </p>
          <form
            [formGroup]="verifyBankForm"
            [bitSubmit]="verifyBank"
            class="tw-flex tw-flex-wrap tw-items-center tw-space-x-2"
          >
            <bit-form-field class="tw-w-40">
              <bit-label>{{ "amountX" | i18n: "1" }}</bit-label>
              <input bitInput type="number" step="1" placeholder="xx" formControlName="amount1" />
              <span bitPrefix>$0.</span>
            </bit-form-field>
            <bit-form-field class="tw-w-40">
              <bit-label>{{ "amountX" | i18n: "2" }}</bit-label>
              <input bitInput type="number" step="1" placeholder="xx" formControlName="amount2" />
              <span bitPrefix>$0.</span>
            </bit-form-field>
            <button bitButton bitFormButton buttonType="primary" type="submit">
              {{ "verifyBankAccount" | i18n }}
            </button>
          </form>
        </bit-callout>
        <p>
          <i class="bwi bwi-fw" [ngClass]="paymentSourceClasses"></i>
          {{ paymentSource.description }}
        </p>
      </ng-container>
      <button
        type="button"
        bitButton
        buttonType="secondary"
        class="payment_trigger_button"
        [bitAction]="changePayment"
      >
        {{ (paymentSource ? "changePaymentMethod" : "addPaymentMethod") | i18n }}
      </button>
      <p *ngIf="isUnpaid" bitTypography="body1">
        {{ "paymentChargedWithUnpaidSubscription" | i18n }}
      </p>
    </bit-section>
  </ng-container>
</bit-container>
