<aside
  class="tw-mb-4 tw-box-border tw-rounded-lg tw-border tw-border-l-4 tw-border-solid tw-bg-background tw-pl-3 tw-pr-2 tw-py-2 tw-leading-5 tw-text-main"
  [ngClass]="calloutClass"
  [attr.aria-labelledby]="titleId"
>
  <header id="{{ titleId }}" class="tw-mb-1 tw-mt-0 tw-text-base tw-font-semibold" *ngIf="title">
    <i class="bwi" [ngClass]="[icon, headerClass]" *ngIf="icon" aria-hidden="true"></i>
    {{ title }}
  </header>
  <div bitTypography="body2">
    <ng-content></ng-content>
  </div>
</aside>
