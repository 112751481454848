<ng-template>
  <div
    class="tw-inline-block tw-w-11/12 tw-pl-7"
    [ngClass]="{
      'tw-border-0 tw-border-l tw-border-solid tw-border-secondary-300': applyBorder,
      'tw-pt-6': addSubLabelSpacing,
    }"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
