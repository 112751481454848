<h1 class="tw-text-4xl !tw-text-alt2">The Bitwarden Password Manager</h1>
<div class="tw-pt-32">
  <h2 class="tw-text-2xl">
    Trusted by millions of individuals, teams, and organizations worldwide for secure password
    storage and sharing.
  </h2>
</div>
<ul class="tw-mt-12 tw-flex tw-flex-col tw-gap-10 tw-text-2xl tw-text-main">
  <li>Store logins, secure notes, and more</li>
  <li>Collaborate and share securely</li>
  <li>Access anywhere on any device</li>
  <li>Create your account to get started</li>
</ul>
<div class="tw-mt-28">
  <app-logo-company-testimonial></app-logo-company-testimonial>
</div>
