<bit-item *ngFor="let credential of credentials$ | async">
  <bit-item-content>
    <bit-color-password class="tw-font-mono" [password]="credential.credential" />
    <div slot="secondary">
      {{ credential.generationDate | date: "medium" }}
    </div>
  </bit-item-content>
  <ng-container slot="end">
    <bit-item-action>
      <button
        type="button"
        bitIconButton="bwi-clone"
        [appCopyClick]="credential.credential"
        [valueLabel]="getGeneratedValueText(credential)"
        [appA11yTitle]="getCopyText(credential)"
        showToast
      >
        {{ getCopyText(credential) }}
      </button>
    </bit-item-action>
  </ng-container>
</bit-item>
