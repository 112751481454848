<bit-callout type="warning" title="{{ 'verifyBankAccount' | i18n }}">
  <p>{{ "verifyBankAccountWithStatementDescriptorInstructions" | i18n }}</p>
  <form [formGroup]="formGroup" [bitSubmit]="submit">
    <bit-form-field class="tw-mr-2 tw-w-48">
      <bit-label>{{ "descriptorCode" | i18n }}</bit-label>
      <input bitInput type="text" placeholder="SMAB12" formControlName="descriptorCode" />
    </bit-form-field>
    <button *ngIf="onSubmit" type="submit" bitButton bitFormButton buttonType="primary">
      {{ "submit" | i18n }}
    </button>
  </form>
</bit-callout>
