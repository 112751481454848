<ng-template>
  <section cdkTrapFocus cdkTrapFocusAutoCapture class="tw-relative" role="dialog" aria-modal="true">
    <div class="tw-overflow-hidden tw-rounded-md tw-border tw-border-solid tw-border-secondary-300">
      <div
        class="tw-relative tw-z-20 tw-w-72 tw-break-words tw-bg-background tw-pb-4 tw-pt-2 tw-text-main"
      >
        <div class="tw-mb-1 tw-mr-2 tw-flex tw-items-start tw-justify-between tw-gap-4 tw-pl-4">
          <h2 class="tw-mb-0 tw-mt-1 tw-text-base tw-font-semibold">
            {{ title }}
          </h2>
          <button
            type="button"
            bitIconButton="bwi-close"
            [attr.title]="'close' | i18n"
            [attr.aria-label]="'close' | i18n"
            (click)="closed.emit()"
          ></button>
        </div>
        <div class="tw-px-4">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <div class="bit-popover-arrow"></div>
  </section>
</ng-template>
