<tools-password-generator
  *ngIf="type === 'password'"
  [disableMargin]="disableMargin"
  (onGenerated)="onCredentialGenerated($event)"
  (onAlgorithm)="algorithm($event)"
></tools-password-generator>
<tools-username-generator
  *ngIf="type === 'username'"
  [disableMargin]="disableMargin"
  (onGenerated)="onCredentialGenerated($event)"
  (onAlgorithm)="algorithm($event)"
></tools-username-generator>
