<app-header></app-header>

<bit-container>
  <tools-credential-generator />
  <button
    bitLink
    type="button"
    linkType="primary"
    aria-haspopup="true"
    (click)="openHistoryDialog()"
  >
    {{ "generatorHistory" | i18n }}
  </button>
</bit-container>
