import { svgIcon } from "@bitwarden/components";

export const WaveIcon = svgIcon`
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 100">
  <path
    class="tw-stroke-art-primary"
    stroke-linejoin="round"
    stroke-width="2"
    d="M48.603 49.08c.188-.341.365-.688.566-1.022 4.409-7.312 8.826-14.62 13.228-21.936 2.131-3.54.316-7.806-3.688-8.72-2.335-.533-4.776.562-6.204 2.844-2.924 4.673-5.823 9.362-8.732 14.043-2.2 3.542-4.384 7.094-6.609 10.62-1.844 2.926-4.231 5.315-7.246 7.035-1.851 1.057-2.841.543-3.122-1.554-.548-4.105-1.184-8.194-2.445-12.155-1.625-5.1-6.98-7.558-11.909-5.469-1.134.482-1.361.949-1.091 2.137.703 3.086 1.53 6.152 2.06 9.27.79 4.638.403 9.275-.4 13.894a90.84 90.84 0 0 0-1.352 14.404c-.062 4.798 1.476 8.947 4.824 12.337 3.655 3.702 7.422 7.313 11.933 9.989 7.128 4.23 14.348 4.162 21.605.282 3.89-2.08 7.273-4.84 10.478-7.827 8.691-8.101 5.227-5.375 16.072-11.488 4.7-2.65 9.434-5.242 14.15-7.863 2.708-1.505 3.593-4.521 2.146-7.276-1.4-2.666-4.426-3.627-7.186-2.228-6.594 3.345-13.174 6.717-19.76 10.076-.325.165-.652.323-1.085.344.371-.304.734-.619 1.116-.91 7.578-5.801 15.158-11.601 22.737-17.401 1.86-1.425 2.714-3.3 2.344-5.624-.36-2.26-1.707-3.769-3.873-4.483-1.897-.626-3.628-.245-5.257.928-7.792 5.607-15.604 11.188-23.41 16.776-.274.196-.551.389-.974.452.234-.278.454-.572.705-.835 7.24-7.632 14.493-15.25 21.72-22.893 3.354-3.549 1.622-9.313-3.127-10.382-2.274-.512-4.275.06-5.936 1.796-7.056 7.373-14.134 14.721-21.197 22.087-.293.306-.51.686-.764 1.031l-.317-.278Z"
  />
  <path
    class="tw-stroke-art-primary"
    stroke-linecap="round"
    stroke-width="2"
    d="M49.237 2.496c-13.4 0-24.262 10.863-24.262 24.262"
  />
  <path
    class="tw-stroke-art-accent"
    stroke-linecap="round"
    d="M46.57 8.895c-8.393 0-15.196 6.804-15.196 15.197"
  />
  <path
    class="tw-stroke-art-primary"
    stroke-linecap="round"
    stroke-width="2"
    d="M84.49 93.027c13.4 0 24.262-10.863 24.262-24.262"
  />
  <path
    class="tw-stroke-art-accent"
    stroke-linecap="round"
    d="M87.157 86.628c8.393 0 15.197-6.804 15.197-15.197"
  />
</svg>
`;
