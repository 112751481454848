<cdk-virtual-scroll-viewport
  scrollWindow
  [itemSize]="rowSize"
  [ngStyle]="{ paddingBottom: headerHeight + 'px' }"
>
  <table [ngClass]="tableClass">
    <thead
      class="tw-border-0 tw-border-b-2 tw-border-solid tw-border-secondary-300 tw-font-bold tw-text-muted"
    >
      <tr>
        <ng-content select="[header]"></ng-content>
      </tr>
    </thead>
    <tbody>
      <tr *cdkVirtualFor="let r of rows$; trackBy: trackBy" bitRow>
        <ng-container *ngTemplateOutlet="rowDef.template; context: { $implicit: r }"></ng-container>
      </tr>
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>
