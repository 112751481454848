<auth-anon-layout
  [title]="pageTitle"
  [subtitle]="pageSubtitle"
  [icon]="pageIcon"
  [showReadonlyHostname]="showReadonlyHostname"
  [maxWidth]="maxWidth"
  [titleAreaMaxWidth]="titleAreaMaxWidth"
>
  <router-outlet></router-outlet>
  <router-outlet slot="secondary" name="secondary"></router-outlet>
  <router-outlet slot="environment-selector" name="environment-selector"></router-outlet>
</auth-anon-layout>
