<bit-callout *ngIf="accountDeprovisioningEnabled$ | async; else disabledBlock" type="warning">
  {{ "singleOrgPolicyMemberWarning" | i18n }}
</bit-callout>
<ng-template #disabledBlock>
  <bit-callout type="warning">
    {{ "singleOrgPolicyWarning" | i18n }}
  </bit-callout>
</ng-template>

<bit-form-control>
  <input type="checkbox" bitCheckbox [formControl]="enabled" id="enabled" />
  <bit-label>{{ "turnOn" | i18n }}</bit-label>
</bit-form-control>
