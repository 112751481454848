<ng-container *ngIf="syncLoading">
  <i class="bwi bwi-spinner bwi-spin tw-mr-2" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
  {{ "loading" | i18n }}
</ng-container>

<ng-container *ngIf="!syncLoading">
  <app-callout
    type="warning"
    title="{{ 'resetPasswordPolicyAutoEnroll' | i18n }}"
    *ngIf="resetPasswordAutoEnroll"
  >
    {{ "resetPasswordAutoEnrollInviteWarning" | i18n }}
  </app-callout>

  <auth-input-password
    [buttonText]="'createAccount' | i18n"
    [email]="email"
    [loading]="submitting"
    [masterPasswordPolicyOptions]="masterPasswordPolicyOptions"
    (onPasswordFormSubmit)="handlePasswordFormSubmit($event)"
  ></auth-input-password>
</ng-container>
