<form [bitSubmit]="submit" [formGroup]="formGroup">
  <!-- <ng-template> must be within the <form> to ensure that `formControlName` has a parent `formGroup` directive. -->
  <ng-template #formContentTemplate>
    <bit-form-field>
      <bit-label>{{ "accountEmail" | i18n }}</bit-label>
      <input
        bitInput
        appAutofocus
        inputmode="email"
        appInputVerbatim="false"
        type="email"
        formControlName="email"
      />
    </bit-form-field>

    <button
      class="tw-mb-2"
      type="submit"
      bitButton
      bitFormButton
      buttonType="primary"
      [block]="true"
    >
      {{ "requestHint" | i18n }}
    </button>
    <button type="button" bitButton buttonType="secondary" (click)="cancel()" [block]="true">
      {{ "cancel" | i18n }}
    </button>
  </ng-template>

  <!-- Browser -->
  <main *ngIf="clientType === 'browser'" tabindex="-1">
    <ng-container *ngTemplateOutlet="formContentTemplate"></ng-container>
  </main>

  <!-- Web, Desktop -->
  <ng-container *ngIf="clientType !== 'browser'">
    <ng-container *ngTemplateOutlet="formContentTemplate"></ng-container>
  </ng-container>
</form>
